import styled, { css } from 'styled-components';
import { AddCalenderButton } from '../MainEvents/styles';

const RegularFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Bold';

const CommentsContainer = styled.div`
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  width: 100%;
  font-family: ${RegularFont};
  max-height: 500px;
  height: auto;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    @media (max-width: 500px) {
      width: 5px;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #c6c2c2;
    cursor: pointer;
    border-radius: 2.5px;
  }
  ::-webkit-scrollbar-track {
    background: #f2f0f0;
    border-radius: 2.5px;
  }
  > span {
    font-size: 24px;
    letter-spacing: 0px;
    color: rgb(0,0,0);
  }
`;

const CommentContent = styled.div`
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  width: 100%;
  margin-top: 15px;
  ${(props) => props.isReply && css`
    padding: 0 0 0 70px;
    @media (max-width: 500px) {
      padding: 0 0 0 50px;
    }
  `}
`;

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: ${({ noFloat }) => noFloat ? '0 0 0 20px' : '0 20px 0 0'};
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  border-radius: 50%;
  > img {
    width: 100%;
    height: 100%;
    float: left;
    border-radius: 50%;
  }
`;

const CommentSection = styled.div`
  display: inline-block;
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  max-width: calc(100% - 60px);
  padding: 10px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-radius: 8px;
  white-space: pre-line;
  > img{
    width: 212px;
    // height: 166px;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    @media (max-width: 350px) {
      width: 170px;
    }
  }
  span {
    font-size: 16px;
    letter-spacing: 0px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    pointer-events: none;
  }
  
  span:first-child {
    font-family: ${BoldFont};
    text-transform: capitalize;
    span {
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: Rubik-Bold;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      pointer-events: auto;
      cursor: pointer;
    }
  }
  
  > div {
    display: inline-block;
    color: #159fc9;
    margin-top: -3px;
    
    @media (max-width: 500px) {
      margin-top: -5px;
    }
  }

  .CommentDescription {
    display: block;
    font-size: 16px;
    width: 100%;
    text-transform: none !important;
    word-break: break-word;
    font-family: ${RegularFont} !important;
    text-align: justify;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  
  ${({ isReply }) => isReply && css`
    padding: 10px;
  `}
`;

const StyledLink = styled.a`
  color: rgb(21,159,202);
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  margin: ${({ noFloat }) => noFloat ? '0 70px 0 0' : '0 0 0 70px'};
  margin-top: 10px;
  cursor: pointer;
  display: inline-block;
  
  ${({isReply}) => isReply && css`
    margin-left: 0;
  `}
  
  &:focus, &:hover {
    outline: none;
    color: #159fc9;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    margin-left: 50px;
    font-size: 12px;
  }
`;

const TimeContent = styled.span`
  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  text-align: right;
  margin-top: 10px;
  display: inline-block;
  text-align: ${({ noFloat }) => noFloat ? 'left' : 'right'};
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 107px)'};
  
  ${({ isReply }) => isReply && css`
    width: 100%;
    margin-right: 5px;
  `}
  
  @media (max-width: 500px) {
    width: ${({ fullWidth }) => fullWidth ? '100%' : 'calc(100% - 82px)'};
    font-size: 10px;

    ${({ isReply }) => isReply && css`
      width: 100%;
    `}
  }
`;


const NextArrowIcon = styled.span`
  padding-left: 70px;
  margin-top: 10px;
  float: left;
  
  > svg {
    margin-top: 6px;
    width: auto;
  }
  
  > span {
    font-size: 14px;
    color: #159fc9;
  }
`;

const ViewMoreLink = styled.a`
  font-size: 14px;
  color: #159fc9;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  
  ${({ isReply }) => isReply && css`
    padding-left: 70px;
    
    @media (max-width: 500px) {
      padding-left: 0;
    }
  `}
  
  &:focus, &:hover {
    outline: none;
    color: #159fc9;
    text-decoration: none;
  }
  
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const CustomTextArea = styled.textarea`
  float: left;
  border-radius: 8px;
  box-shadow: 0.5px 0.866px 32px rgba(0,0,0,0.17);
  background-color: #ffffff;
  border: none;
  width: calc(100% - 70px);
  padding: 20px;
  resize: none;
`;

const PostButton = styled(AddCalenderButton)`
  margin-top: 15px;
  float: right;
`;

const Loader = styled.div`
  margin: 30px;
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 8px solid gray;
  border-right-color: transparent;
  border-radius: 50%;
  float: left;

  @keyframes rotate {
    0%    { transform: rotate(0deg); }
    100%  { transform: rotate(360deg); }
  }
`;

const TextFieldContainer = styled.div`
  padding-bottom: 10px;
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
  width: 100%;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const SingleComment = styled.div`
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'right' : 'left'};
`;

const ButtonContainer = styled.div`
  text-align: right;
  width: 100%;
  float: ${({ noFloat }) => noFloat ? 'none' : 'left'}
`;

const PostCommentButton = styled(AddCalenderButton)`
  margin-top: 15px;
  float: none;
  height: 31px;
  width: 72px;
  padding: 0;
`;

export { CommentsContainer, CommentContent, CommentSection, StyledLink, TimeContent, NextArrowIcon, ViewMoreLink, CustomTextArea, PostButton,
  Loader, TextFieldContainer, SingleComment, ButtonContainer, PostCommentButton, ImageWrapper };
