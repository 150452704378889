/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CommentContent, CommentSection, CommentsContainer, SingleComment, ImageWrapper } from './styles';
import { FieldContainer } from '../EventDetails/styles';

import Image from '../Image';

import { getDecodedString } from '../../utils/methods';
import { getUserName, getUserProfileImage, getUserId, getUserPosts } from '../../redux/actions';
import { ImageUrl } from '../../utils/constants';
import DOMPurify from 'dompurify';
class Comments extends Component {
  constructor() {
    super();
    this.state = {
      isUpdatedImage: false,
      mentionedUsers: [],
      users: [],
      userIdArray: [],
      taggedUser: '',
    }
  }

  componentDidMount() {
    if(!this.props.userName) this.props.fetchUserName();
    if(!this.props.profileImage) this.props.fetchUserProfileImage();
    if(!this.props.userId) this.props.fetchUserId();
    this.checkBase64(this.props.profileImage);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }

  createSafeHTML = (htmlString) => {
    const sanitizedHTML = DOMPurify.sanitize(htmlString);
    const range = document.createRange();
    const fragment = range.createContextualFragment(sanitizedHTML);
    return fragment; 
  };

  checkBase64(profileImage) {
    if(profileImage) {
      let arr = profileImage.split('/');
      if(arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      }
      else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }

  showProfileImage = (comment, isOwn) => {
    const { userId, profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if(isOwn) {
      if(isUpdatedImage) {
        return (<img src={profileImage} />)
      }
      else {
        return (<Image image={profileImage} />)
      }
    } else if(userId === comment.uid) {
      if(isUpdatedImage) {
        return (<img src={profileImage} alt="avatar" />)
      }
      else {
        return (<Image image={profileImage} alt="avatar" />)
      }
    }
    else {
      return (<Image image={comment.profile_image} alt="avatar" />)
    }
  };

  getTaggedUserId = (id) => {
    const { history, userId } = this.props;
    if(id){
      if(userId === id) {
        history.push('/profile');
      } else {
        history.push(`/profile/${id}`);
      }
    }
  };

  showComment(comment) {
    const sanitizedComment = DOMPurify.sanitize(this.createSafeHTML(getDecodedString(comment).split('\\n').join('<br />')));
    return (
      <span className="CommentDescription">{sanitizedComment}</span>
    );
    // return (<span className="CommentDescription" dangerouslySetInnerHTML={{__html: getDecodedString(comment).split('\\n').join('<br />')}} />)
  }

  showImage(image) {
    return (<img src={`${ImageUrl}/${image}`} />)
  }

  render() {
    const { comments, fixHeight, noFloat, userId } = this.props;
    return (
      <CommentsContainer noFloat={noFloat}>
        <FieldContainer fixHeight={fixHeight} commentsSection noFloat={noFloat}>
          {
            comments.map((comment, index) => (
              <SingleComment key={index} noFloat={comment.uid !== userId ? 0 : 1}>
                <CommentContent noFloat={comment.uid !== userId ? 0 : 1}>
                  <ImageWrapper noFloat={comment.uid !== userId ? 0 : 1} onClick={() => this.getTaggedUserId(comment.uid)}>
                    {this.showProfileImage(comment, false)}
                  </ImageWrapper>
                  <CommentSection noFloat={comment.uid !== userId ? 0 : 1}>
                    {comment && comment.image ? [this.showImage(comment.image), this.showComment(comment.comment)] : this.showComment(comment.comment)}
                  </CommentSection>
                </CommentContent>
              </SingleComment>
            ))
          }
        </FieldContainer>
      </CommentsContainer>
    )
  }
}

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  fixHeight: PropTypes.bool,
  postReply: PropTypes.func,
  id: PropTypes.number,
  replySuccess: PropTypes.string,
  noFloat: PropTypes.bool,
  fetchUserName: PropTypes.func.isRequired,
  userName: PropTypes.string,
  fetchUserProfileImage: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  fetchUserId: PropTypes.func.isRequired,
  userId: PropTypes.number,
  fetchUserPosts: PropTypes.func.isRequired,
  history: PropTypes.object,
  userCompany: PropTypes.object,
  isChallengeOrEvent: PropTypes.bool,
  enableTrigger: PropTypes.bool
};

const mapStateToProps = (state) => ({
  userName: state.profileData.userName,
  profileImage: state.profileData.profileImage,
  userId: state.profileData.userId,
  userCompany: state.profileData.userCompany,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserName: () => dispatch(getUserName()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  fetchUserId: () => dispatch(getUserId()),
  fetchUserPosts: (id, history) => dispatch(getUserPosts(id, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
